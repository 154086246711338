import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto nacl box`}</strong>{` -- authenticate and encrypt small messages using public-key cryptography`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto nacl box <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto nacl box`}</strong>{` command group uses public-key cryptography to encrypt,
decrypt and authenticate messages. The implementation is based on NaCl's
crypto_box function.`}</p>
    <p>{`NaCl crypto_box function is designed to meet the standard notions of
privacy and third-party unforgeability for a public-key authenticated-encryption
scheme using nonces. For formal definitions see, e.g., Jee Hea An,
"Authenticated encryption in the public-key setting: security notions and
analyzes," `}<a parentName="p" {...{
        "href": "https://eprint.iacr.org/2001/079"
      }}>{`https://eprint.iacr.org/2001/079`}</a>{`. Distinct messages between the same
{sender, receiver} set are required to have distinct nonces. For example, the
lexicographically smaller public key can use nonce 1 for its first message to
the other key, nonce 3 for its second message, nonce 5 for its third message,
etc., while the lexicographically larger public key uses nonce 2 for its first
message to the other key, nonce 4 for its second message, nonce 6 for its third
message, etc. Nonces are long enough that randomly generated nonces have
negligible risk of collision.`}</p>
    <p>{`There is no harm in having the same nonce for different messages if the {sender,
receiver} sets are different. This is true even if the sets overlap. For
example, a sender can use the same nonce for two different messages if the
messages are sent to two different public keys.`}</p>
    <p>{`By default nonces are alphanumeric, but it's possible to use binary nonces using
the prefix 'base64:' and the standard base64 encoding of the data, e.g.
'base64:081D3pFPBkwx1bURR9HQjiYbAUxigo0Z'. The prefix 'string:' is also
accepted, but it will be equivalent to not using a prefix. Nonces cannot be
longer than 24 bytes.`}</p>
    <p>{`NaCl crypto_box is not meant to provide non-repudiation. On the contrary: they
guarantee repudiability. A receiver can freely modify a boxed message, and
therefore cannot convince third parties that this particular message came from
the sender. The sender and receiver are nevertheless protected against forgeries
by other parties. In the terminology of
`}<a parentName="p" {...{
        "href": "https://groups.google.com/group/sci.crypt/msg/ec5c18b23b11d82c"
      }}>{`https://groups.google.com/group/sci.crypt/msg/ec5c18b23b11d82c`}</a>{`, NaCl crypto_box
uses "public-key authenticators" rather than "public-key signatures."`}</p>
    <p>{`Users who want public verifiability (or receiver-assisted public verifiability)
should instead use signatures (or signcryption).`}</p>
    <p>{`NaCl crypto_box is curve25519xsalsa20poly1305, a particular combination of
Curve25519, Salsa20, and Poly1305 specified in "Cryptography in NaCl". This
function is conjectured to meet the standard notions of privacy and third-party
unforgeability.`}</p>
    <p>{`These commands are interoperable with NaCl: `}<a parentName="p" {...{
        "href": "https://nacl.cr.yp.to/box.html"
      }}>{`https://nacl.cr.yp.to/box.html`}</a></p>
    <h2>{`Examples`}</h2>
    <p>{`Create a keypair for encrypting/decrypting messages:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`# Bob
$ step crypto nacl box keypair bob.box.pub bob.box.priv

# Alice
$ step crypto nacl box keypair alice.box.pub alice.box.priv
`}</code></pre>
    <p>{`Bob encrypts a message for Alice using her public key and signs it using his
private key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo message | step crypto nacl box seal nonce alice.box.pub bob.box.priv
0oM0A6xIezA6iMYssZECmbMRQh77mzDt
`}</code></pre>
    <p>{`Alice receives the encrypted message and the nonce and decrypts with her
private key and validates the message from Bob using his public key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo 0oM0A6xIezA6iMYssZECmbMRQh77mzDt | step crypto nacl box open nonce bob.box.pub alice.box.priv
message
`}</code></pre>
    <p>{`Decrypt the message using a base64 nonce:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo 0oM0A6xIezA6iMYssZECmbMRQh77mzDt | step crypto nacl box open base64:bm9uY2U= bob.box.pub alice.box.priv
message
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "keypair/"
              }}>{`keypair`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`generate a key for use with seal and open`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "open/"
              }}>{`open`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`authenticate and decrypt a box produced by seal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "seal/"
              }}>{`seal`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`produce an authenticated and encrypted ciphertext`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      